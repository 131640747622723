import styled from "styled-components";

export const StyledFooter = styled.footer`
  margin-top: 30px;
  background-color: #333;
  color: #fff;
  padding: 70px;
  text-align: center;
`;

export const StyledText = styled.p`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
`;

export const StyledLink = styled.a`
  text-decoration: underline;
  color: #fff;
`;
