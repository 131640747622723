import React from "react";
import { useState, useEffect, useRef } from "react";
import { SERVER_API } from "../../../common/env.js";
import { Toast } from "primereact/toast";
import Button from "@mui/material/Button";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import PersonIcon from "@mui/icons-material/Person";
import PersonOffIcon from "@mui/icons-material/PersonOff";

export default function ManageClients(props) {
  const toast = useRef();

  const [editItem, setEditItem] = useState(null);
  const [item, setItem] = useState(null);
  const [items, setItems] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  const columns = [
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Редагувати"
          onClick={() => {
            setEditItem(params.row);
            setOpenDialog(true);
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Видалити"
          onClick={async () => {
            if (
              !window.confirm(
                `${params.row.active ? "Видалити" : "Відновити"} користувача ${
                  params.row.phone
                } / ${params.row.fio} ?`
              )
            )
              return;
            const id = params.row.id;
            try {
              const response = await fetch(`${SERVER_API}/admin/users/${id}`, {
                method: "DELETE",
                headers: new Headers({
                  Authorization: "Bearer " + localStorage.getItem("jwt"),
                }),
              });
              if (!response.ok)
                throw new Error(`HTTP error! status: ${response.status}`);
              const json = await response.json();
              toast.current.show({
                severity: "info",
                summary: "",
                detail: "Запис оновлено.",
                life: 3000,
              });
              load();
            } catch (err) {
              console.log(err);
              toast.current.show({
                severity: "error",
                summary: "Помилка!",
                detail: `Помилка виконання`,
              });
            } finally {
            }
          }}
        />,
      ],
    },
    {
      field: "active",
      headerName: "",
      renderCell: (params) =>
        params.row.active ? <PersonIcon /> : <PersonOffIcon />,
      width: 50,
    },
    { field: "phone", headerName: "Телефон", width: 150 },
    { field: "fio", headerName: "ПІБ", flex: 1 },
  ];

  useEffect(() => {
    (async () => await load())();
  }, []);

  const load = async () => {
    try {
      const response = await fetch(`${SERVER_API}/admin/users`, {
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("jwt"),
        }),
      });
      if (!response.ok)
        throw new Error(`HTTP error! status: ${response.status}`);
      const json = await response.json();
      const data = json.data;
      setItems(data);
    } catch (err) {
      console.log(err);
      toast.current.show({
        severity: "error",
        summary: "Помилка!",
        detail: `Помилка виконання`,
      });
    } finally {
    }
  };

  const editDialog = () => {
    const handleClickSave = async () => {
      if (!editItem.phone.trim() || !editItem.fio.trim()) {
        return alert("Не всі параметри введені!");
      }
      try {
        const response = !editItem.id
          ? await fetch(`${SERVER_API}/admin/users`, {
              method: "POST",
              headers: new Headers({
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("jwt"),
              }),
              body: JSON.stringify({
                phone: editItem.phone,
                fio: editItem.fio,
              }),
            })
          : await fetch(`${SERVER_API}/admin/users/${editItem.id}`, {
              method: "PUT",
              headers: new Headers({
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("jwt"),
              }),
              body: JSON.stringify({
                phone: editItem.phone,
                fio: editItem.fio,
              }),
            });

        if (!response.ok)
          throw new Error(`HTTP error! status: ${response.status}`);
        load();
        toast.current.show({
          severity: "success",
          summary: "Повідомлення",
          detail: `Дані оновлено.`,
        });
        setOpenDialog(false);
      } catch (err) {
        console.log(err);
        toast.current.show({
          severity: "error",
          summary: "Помилка!",
          detail: `Помилка виконання`,
        });
      } finally {
      }
    };

    const handleCloseDialog = () => {
      setOpenDialog(false);
      setItem(null);
    };

    return (
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Редагування</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            label="Телефон"
            margin="dense"
            fullWidth
            variant="outlined"
            value={editItem?.phone}
            onChange={(e) => {
              const o = Object.assign({}, editItem);
              o.phone = e.target.value;
              setEditItem(o);
            }}
          />
          <TextField
            label="П.І.Б."
            margin="dense"
            fullWidth
            variant="outlined"
            value={editItem?.fio}
            onChange={(e) => {
              const o = Object.assign({}, editItem);
              o.fio = e.target.value;
              setEditItem(o);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickSave}>Зберегти</Button>
          <Button onClick={handleCloseDialog}>Відмінити</Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <Toast ref={toast} />
      <Box
        sx={{
          "& .red": {
            backgroundColor: "#ffffff",
            color: "#ff0000",
          },
          "& .default": {
            backgroundColor: "#ffffff",
            color: "#000000",
          },
          "& .green": {
            backgroundColor: "white",
            color: "green",
          },
        }}
      >
        {/* <h1>Manage Users</h1> */}
        <Button
          variant="text"
          onClick={(e) => {
            setEditItem({ id: null, phone: "", fio: "" });
            setOpenDialog(true);
          }}
        >
          Додати
        </Button>

        <DataGrid
          autoHeight
          rows={items}
          columns={columns}
          onSelectionModelChange={(value) =>
            setItem(items.find((item) => item.id == value))
          }
          getCellClassName={(params) => {
            if (params.field === "active") {
              return params.row.active ? "green" : "red";
            }
            if (["phone", "fio"].includes(params.field) && !params.row.active) {
              return "red";
            }
            return "";
          }}
        />
      </Box>
      {editDialog()}
    </>
  );
}
