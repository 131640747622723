import React from "react";

import { Box } from "@mui/material";

export const Error = () => {
  return (
    <Box
      sx={{
        width: 1,
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h1>Помилка 404</h1>
      <h2>Запинувана сторінка не знайдена</h2>
      <h3>Скористайтесь меню сайту для переходу на потрібну сторінку</h3>
    </Box>
  );
};
