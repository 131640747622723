import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Avatar from "@mui/material/Avatar";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import { API_URL } from "../common/env.js";
import { ROUTES } from "../Routes/Rotuer.js";
//import { default as jwt } from "jsonwebtoken"
import { jwtDecode } from "jwt-decode";

const theme = createTheme();

// Idle Timer
function logoutTimer() {
  let idleTimer = 60 * 1000;
  let logOutTimer;

  function resetTimer() {
    clearTimeout(logOutTimer);
    logOutTimer = setTimeout(logOut, idleTimer);
  }

  function logOut() {
    console.log("LogOUT");
    window.location.href = ROUTES.AUTH;
    localStorage.removeItem("jwt");
    localStorage.removeItem("enterprise");
    localStorage.removeItem("phone");
  }

  document.addEventListener("mousemove", resetTimer);
  document.addEventListener("keypress", resetTimer);

  resetTimer();

  return {
    reset: resetTimer,
  };
}

const LoginEnterprise = (props) => {
  const [enterprise, setEnterprise] = useState("");
  const [phone, setPhone] = useState("");
  //const [userName, setUserName] = useState("");
  const [sms, setSms] = useState("");
  const [open, setOpen] = useState(false);

  const handleCancelModal = () => {
    setOpen(false);
  };

  const handleOkModal = async () => {
    try {
      const response = await fetch(API_URL + "/api/v1/auth/sms", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ enterprise, phone, code: sms }),
      });

      if (response.ok) {
        const json = await response.json();
        console.log('SignIn.js handleOkModal',json);
        if (json.error) {
          throw Error("Помилка аутентифікації");
        }
        const tkobj = jwtDecode(json.token);
        localStorage.setItem("jwt", json.token);
        localStorage.setItem("enterprise", json.token);
        localStorage.setItem("phone", phone);
        localStorage.setItem("username", tkobj.username);
        //localStorage.setItem("JSON",JSON.stringify(tkobj.username));

        const timer = logoutTimer(); // Start the timer

        window.location.href = ROUTES.ENT;
      } else {
        throw Error("Помилка аутентифікації!");
      }
      setOpen(false);
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(API_URL + "/api/v1/auth", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ enterprise, phone }),
      });
      if (response.ok) {
        const json = await response.json();
        if (json.error) throw Error("Помилка аутентифікації!");
      } else {
        throw Error("Error requst to server!");
      }
      setOpen(true);
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  const dialog = (
    <Dialog open={open}>
      <DialogContent>
        <DialogContentText>Введіть код SMS.</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          fullWidth
          variant="standard"
          value={sms}
          onInput={(e) => setSms(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOkModal}>Ок</Button>
        <Button onClick={handleCancelModal}>Відмінити</Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      {dialog}
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ mt: 1 }}
        {...props}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          id="entreprise"
          label="Код"
          name="entreprise"
          autoFocus
          value={enterprise}
          onInput={(e) => setEnterprise(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="phone"
          label="Телефон"
          name="phone"
          value={phone}
          onInput={(e) => setPhone(e.target.value)}
        />
        <Button
          type="submit"
          disabled={enterprise.trim() === "" || phone.trim() === ""}
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Вхід
        </Button>
      </Box>
    </>
  );
};

export default function SignIn() {
  document.title = `EXMOTO: авторизація`;

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            width: 1,
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Авторизація
          </Typography>

          <LoginEnterprise />
        </Box>
      </Container>
    </ThemeProvider>
  );
}
