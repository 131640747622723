import styled from "styled-components";

export const StyledHeader = styled.header`
  background-color: #f9f9f9;
  /* box-shadow: 0 0 16px rgba(0, 0, 0, 0.2); */
  margin-bottom: 15px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const HeaderContainer = styled.div`
  padding-top: 5px;
  padding-bottom: 0px;
  width: 100%;
`;

export const Navigation = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
`;
