import React from "react";
import { ROUTES } from "./Rotuer";
import { Navigate } from "react-router-dom";

export const RestrictedRouts = ({
  component: Component,
  redirectTo = ROUTES.ENT,
}) => {
  const isLoggedIn = localStorage.getItem("jwt");

  return isLoggedIn ? <Navigate to={redirectTo} /> : Component;
};
