export const ROUTES = {
  MAIN: "/",
  AUTH: "/auth",

  ENT: "/enterprise",
  IND: "/individual",
  ADMIN: "admin",

  USERS: "users",
  PAYERS: "payers",

  CLIENT: "client",
  TTN: "ttn",
  ENTTTVIEW: "/ttn/view",

  ORDER: "/order",
  ORDERVIEW: "/order/view",
  TTNVIEW: "/ttn/view",
  VIEW: "/view",
  ID: "/:id",
  ERROR: "*",
  POLICY: "/policy",
};
