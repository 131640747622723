import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import SignIn from "./component/SignIn";
import ManageClients from "./component/ent/ManageClients.js";
import Ttn from "./component/ent/ttn/Ttn.js";
import { default as ViewTtnEnt } from "./component/ent/ttn/ViewTtn.js";
import { default as AdminUsers } from "./component/ent/admin/Users.js";
import { default as Payer } from "./component/ent/admin/Payer.js";
import { ROUTES } from "./Routes/Rotuer";
import { SharedLayout } from "./component/SharedLayout/SharedLayout";
import { AppWrapper } from "./App.styled";
import { GlobalStyle } from "./component/GlobalStyle/GlobalStyle.styled";
import { Error } from "./component/Error/Error";
import { PrivateRoute } from "./Routes/PrivateRoute";
import { RestrictedRouts } from "./Routes/RestrictedRouts";

export default function App() {
  const [userInactive, setUserInactive] = useState(false);

  useEffect(() => {
    let inactivityTimer;

    const resetTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(() => {
        localStorage.removeItem("jwt");
        localStorage.removeItem("enterprise");
        localStorage.removeItem("phone");
        setUserInactive(true);
      }, 30 * 60 * 1000);
    };

    const onUserActivity = () => {
      resetTimer();
      setUserInactive(false);
    };

    window.addEventListener("mousemove", onUserActivity);
    window.addEventListener("keydown", onUserActivity);

    resetTimer();

    return () => {
      clearTimeout(inactivityTimer);
      window.removeEventListener("mousemove", onUserActivity);
      window.removeEventListener("keydown", onUserActivity);
    };
  }, []);

  return (
    <AppWrapper>
      <GlobalStyle />
      <Router>
        <Routes>
          {userInactive ? (
            <Route path="/*" element={<Navigate to={ROUTES.MAIN} />} />
          ) : (
            <>
              <Route
                path={ROUTES.MAIN}
                element={<RestrictedRouts component={<SignIn />} />}
              />
              <Route
                path={ROUTES.ENT}
                element={<PrivateRoute component={<SharedLayout />} />}
              >
                <Route index element={<ViewTtnEnt />} />
                <Route path={ROUTES.CLIENT} element={<ManageClients />} />
                <Route path={ROUTES.TTN} element={<Ttn />} />
                <Route path={ROUTES.ADMIN}>
                  <Route path={ROUTES.USERS} element={<AdminUsers />} />
                  <Route path={ROUTES.PAYERS} element={<Payer />} />
                </Route>
                <Route path={ROUTES.ERROR} element={<Error />} />
              </Route>
            </>
          )}
        </Routes>
      </Router>
    </AppWrapper>
  );
}
