import { React, useState, useEffect, useRef } from "react";
import { Box, TextField, FormControl } from "@mui/material";
import { SERVER_API } from "../../../common/env.js";

export default function Payer() {
  const [payer, setPayer] = useState(null);

  const load = async () => {
    try {
      const response = await fetch(`${SERVER_API}/clients/payer`, {
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("jwt"),
        }),
      });
      if (!response.ok)
        throw new Error(`HTTP error! status: ${response.status}`);
      const json = await response.json();
      const data = json.data;
      console.log('PAYER', data[0])
      setPayer(data[0]);

    } catch (err) {
      console.log(err);
      // toast.current.show({ severity: 'error', summary: 'Помилка!', detail: `Помилка виконання` })
    } finally {
    }
  };

  useEffect(() => {
    console.log('PAYER ONE Cons')
     load();
  }, []);

  return (
    <>
      <Box>
        <h4>Платник</h4>
        <TextField
          label="CACC"
          variant="outlined"
          margin="dense"
          fullWidth
          value={payer?.cacc || ''}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          label="Найменування"
          variant="outlined"
          margin="dense"
          fullWidth
          value={payer?.leg_name || '' }
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          label="Адреса"
          variant="outlined"
          margin="dense"
          fullWidth
          value={payer?.leg_adr || ''}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          label="Код ЄДРПОУ"
          variant="outlined"
          margin="dense"
          fullWidth
          value={payer?.zkpo || ''}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          label="ІПН"
          variant="outlined"
          margin="dense"
          fullWidth
          value={payer?.nip || ''}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          label="Св-во реєстрації ПДВ"
          variant="outlined"
          margin="dense"
          fullWidth
          value={payer?.vat_reg || ''}
          InputProps={{
            readOnly: true,
          }}
        />
        {/* <TextField label="Дата видачі ПДВ" variant="outlined" margin="dense" fullWidth
            value={payer?.vat_reg_date.substring(0,10) || ''}
            InputProps={{
              readOnly: true,
            }}
          /> */}

        <TextField
          label="Дата видачі ПДВ"
          variant="outlined"
          margin="dense"
          fullWidth
          value={payer?.vat_reg_date?.substring(0, 10) || ''}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          label="Р/р"
          variant="outlined"
          margin="dense"
          fullWidth
          value={payer?.locacct || ''}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          label="МФО"
          variant="outlined"
          margin="dense"
          fullWidth
          value={payer?.bankmfo || ''}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          label="Банк"
          variant="outlined"
          margin="dense"
          fullWidth
          value={payer?.bankname || ''}
          InputProps={{
            readOnly: true,
          }}
        />
      </Box>
      <hr />
    </>
  );
}
