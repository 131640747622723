import { React, useContext, useState, useEffect, useRef } from 'react'
import { SenderContext, ReceiverContext, ParamsContext } from './Context'
import { SERVER_API } from '../../../common/env'
import { Toast } from 'primereact/toast'
import { Box } from '@mui/material'


export default function Cost(props) {
  const toast = useRef()
  const [params, setParams] = useContext(ParamsContext)
  const [sender, setSender] = useContext(SenderContext)
  const [receiver, setReceiver] = useContext(ReceiverContext)
  const [cost, setCost] = useState(null)

  useEffect(() => {
    calculate()
  }, [sender, receiver, params])

  const calculate = async () => {
    if (!sender || !receiver) return

    const data = {
      org: sender.c_cityid,
      dst: receiver.c_cityid,
      firmid: 0,
      planno: 22,
      usewt: params.weight,
      tsrv: params.service,
      adate: null,
      tpak: params.cargo,
      pcs: params.place,
      cost: params.cost
    }

    try {
      const response = await fetch(`${SERVER_API}/calccost`, {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('jwt')
        }),
        body: JSON.stringify({ data: data }),
      })
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)
      const json = await response.json()
      setCost(json.data)
    } catch (err) {
      console.log(err)
      toast.current.show({ severity: 'error', summary: 'Помилка!', detail: `Розрахунок вартості: помилка виконання!` })
    } finally {

    }
  }


  return (
    <>
      <Toast ref={toast} />
      <h4>Вартість доставки ...</h4>
      <Box>
          <table>
            <tbody>
              <tr><td>Основний (базовий) тариф без ПДВ, грн.</td><td>{cost?.B_chg.toFixed(2)}</td></tr>
              <tr><td>Оціночний тариф ({cost?.PROC_ESTIMATED_TARIFF}% від заявленої вартості), грн.</td><td>{cost?.V_chg.toFixed(2)}</td></tr>
              <tr><td>Додатковий тариф за дооформлення, грн.</td><td>{cost?.A_chg.toFixed(2)}</td></tr>
              <tr><td>Сума тарифів без ПДВ, грн.</td><td>{cost?.T_chg.toFixed(2)}</td></tr>
              <tr><td>ПДВ, грн.</td><td>{(cost?.T_chg || 0 * 0.2).toFixed(2)}</td></tr>
              <tr><td>Всього з ПДВ, грн.</td><td>{(cost?.T_chg || 0 * 1.2).toFixed(2)}</td></tr>
            </tbody>
          </table>
      </Box>
    </>
  )
}