import React, { useEffect, useRef, useState, useContext } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { TextField, Box, Button, Autocomplete, Popper } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { SERVER_API } from "../../../common/env.js";
import { Toast } from "primereact/toast";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import City from "../../Сity.js";
import { ParamsContext } from "./Context.js";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";

const CityContext = React.createContext();

export default function WhoIs(props) {
  const toast = useRef(null);
  const [context, setContext] = useContext(props.context);

  const [dptNovPost, setDptNovPost] = useState([]);
  const [checked, setChecked] = useState(false);
  const [params, setParams] = useContext(ParamsContext);

  const [city, setCity] = useState({ country_id: 20, city_id: null });
  const [items, setItems] = useState([]);
  const [item, setItem] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  /* edit value */
  const [address, setAddress] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phone, setPhone] = useState("");
  const [person, setPerson] = useState("");
  const [edrpou, setEdrpou] = useState("");
  const [openWait, setOpenWait] = useState(false);

//console.log('props.context',context)
//console.log('WHOIS_______',city)

  useEffect(() => {
    // if (!context?.id) {
    setDptNovPost([]);
    setChecked(false);
    setParams({ ...params, dptnp: "" });
    // }
  }, [context]);

  // список отделений Новой Почты
  useEffect(() => {
    if (checked) {
      setOpenWait(true);
// console.log('WhoIs Contex', context)
      if (context && context.c_city) {
        const { c_city } = context;
        fetch(`${SERVER_API}/ttn/dptnp?city=${c_city}`, {
       //   fetch(`https://exmoto.com:63011/api/v1/ttn/dptnp?city=${c_city}`, {
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          }),
        })
          .then((response) => {
            if (!response.ok) {
              console.log(response.statusText);
              throw new Error(response.statusText);
            }
            return response;
          })
          .then((response) => response.json())
          .then((data) => {
            setDptNovPost(data.data.dpt);
          })
          .catch((e) => setDptNovPost([]));
      }
      setTimeout(() => setOpenWait(false), 1000);
    } else {
      setParams({ ...params, dptnp: "" });
      setDptNovPost([]);
    }
  }, [checked]);

  const load = async (id) => {
    try {
      const response = await fetch(`${SERVER_API}/clients`, {
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("jwt"),
        }),
      });
      if (!response.ok)
        throw new Error(`HTTP error! status: ${response.status}`);
      const json = await response.json();
      const data = json.data;
      console.log('WhoIs Data Clients', data)
      setItems(data);
      if (id) {
        const item = data.find((e) => e.id === id);
        setItem(item);
      }
    } catch (err) {
      console.log(err);
      toast.current.show({
        severity: "error",
        summary: "Помилка!",
        detail: `Помилка виконання`,
      });
    } finally {
    }
  };

  useEffect(() => {
    setContext(item);
  }, [item]);

  useEffect(() => {load()}, []);

  const handleOpenDialog = () => {
    setAddress("");
    setCity({ country_id: 20, city_id: null });
    setCompanyName("");
    setPhone("");
    setEdrpou("");
    setPerson("");
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const EditDialog = () => {
    const [validationErrors, setValidationErrors] = useState({});
  //  console.log('EditDialog TTN', typeof(companyName), typeof(person), typeof(edrpou));

    const handleClickSave = async () => {
      const errors = {};

      if (!companyName.trim()) {
        errors.companyName = "Компанія не може бути порожньою!";
      }
      console.log('WHOIS !!!!!', city)
      if (!city.city_id) {
        errors.city = "Не вказаний населений пункт!";
      }

      if (!address.trim()) {
        errors.address = "Адреса не може бути порожньою!";
      }

      if (!person.trim()) {
        errors.person = "Контактна особа не може бути порожньою!";
      }    
      const phoneRegex = /^\+38\d{10}$/;
      const isValidPhone = phoneRegex.test(phone);
      if (!isValidPhone) {
        errors.phone =
          "Неправильний формат телефону! Почніть з +38 і додайте 10 цифр.";
      }
      if (!!edrpou) {
        const edrpouRegex = /^\d{8}(?:\d{2})?$/;
        const isValidEdrpou = edrpouRegex.test(edrpou);
        console.log('EDRPOU', edrpou.trim(), edrpouRegex.test(edrpou))
        if (!isValidEdrpou) {
          errors.edrpou =
            "Неправильний формат коду ЄДРПОУ! Додайте 8 або 10 цифр.";
        }
      }

      if (Object.keys(errors).length > 0) {
        setValidationErrors(errors);
        return;
      }
      
      try {
         setValidationErrors({});

        const response = await fetch(`${SERVER_API}/clients`, {
          method: "POST",
          headers: new Headers({
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          }),
          body: JSON.stringify({
            phone: phone,
            edrpou: edrpou.toString(),
            company_name: companyName,
            address: address,
            city: city.city_id,
            person: person,
          }),
        });

        if (!response.ok)
          throw new Error(`HTTP error! status: ${response.status}`);
        const json = await response.json();
        const data = json.data;
        const id = data[0].id;
        console.log('response whois', data);
        load(id);
        toast.current.show({
          severity: "success",
          summary: "Повідомлення",
          detail: `Дані оновлено.`,
        });
        setOpenDialog(false);
      } catch (err) {
        console.log(err);
        toast.current.show({
          severity: "error",
          summary: "Помилка!",
          detail: `Помилка виконання`,
        });
      }
    };

    return (
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Додавання клієнта</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            label="Компанія"
            required
            margin="dense"
            fullWidth
            variant="outlined"
            value={companyName}
            onChange={(e) => {setCompanyName(e.target.value)
                                    setValidationErrors({})}}
            error={!!validationErrors.companyName}
            helperText={validationErrors.companyName}
          />
          <City context={CityContext}
            label="Почніть вводити назву населеного пункту..."
            error={!!validationErrors.city}
            helperText={validationErrors.city}
          />
          <TextField
            label="Адреса"
            required
            margin="dense"
            fullWidth
            variant="outlined"
            value={address}
            onChange={(e) => {setAddress(e.target.value) 
              setValidationErrors({}) }}
            error={!!validationErrors.address}
            helperText={validationErrors.address}
          />
          <TextField
            label="Контактна особа"
            required
            margin="dense"
            fullWidth
            variant="outlined"
            value={person}
            onChange={(e) => {setPerson(e.target.value)
              setValidationErrors({})}}
            error={!!validationErrors.person}
            helperText={validationErrors.person}
          />
          <TextField
            label="Телефон"
            required
            margin="dense"
            fullWidth
            variant="outlined"
            value={phone}
          onChange={(e) => {setPhone(e.target.value)
            setValidationErrors({})}}
            error={!!validationErrors.phone}
            helperText={validationErrors.phone}
          />
          <TextField
            label="Код ЄДРПОУ клієнта за потребою"
            required
            margin="dense"
            fullWidth
            variant="outlined"
            value={edrpou}
          onChange={(e) => {setEdrpou(e.target.value)
            setValidationErrors({})}}
            error={!!validationErrors.edrpou}
            helperText={validationErrors.edrpou}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickSave}>Зберегти</Button>
          <Button onClick={handleCloseDialog}>Відмінити</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const NovPost = () => {
    if (props.whois !== 2) return "";
    return (
      <>
        <FormControlLabel
          sx={{ ml: "10px" }}
          control={
            <Switch
              size="small"
              checked={checked}
              disabled={!context?.id}
              onChange={(event) => setChecked(event.target.checked)}
            />
          }
          label="Отримання Новою Поштою"
        />

        <Box sx={{ minWidth: 200 }}>
          <FormControl fullWidth>
            <InputLabel id="np">№ відділення</InputLabel>
            <Select
              labelId="np"
              value={params.dptnp}
              disabled={!(context?.id && checked)}
              label="№ відділення"
              onChange={(event) =>
                setParams({ ...params, dptnp: event.target.value })
              }
            >
              {dptNovPost.map((e, i) => (
                <MenuItem key={i} value={e}>
                  {e}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </>
    );
  };

  return (
    <>
      <Modal
        open={openWait}
        onClose={() => setOpenWait(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <CircularProgress size={"10rem"} />
          </Box>
        </Box>
      </Modal>

      <CityContext.Provider value={[city, setCity]}>
        <Toast ref={toast} />
        <Box sx={{ width: 1 }}>
          <h4> {props.whois === 1 ? "Відправник" : "Отримувач"} </h4>

          <Box sx={{ display: "flex" }}>
            <Autocomplete
              sx={{ width: 300 }}
              autoHighlight={false}
              value={item}
              options={items}
              renderInput={(params) => (
                <TextField {...params} label="Компанія" />
              )}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {`${option.c_co} | ${option.c_city} | ${option.c_adr}`}
                  </li>
                );
              }}
              key={(option) => option.id}
              getOptionLabel={(option) => option?.c_co || ""}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, value) => {
                setItem(value);
              }}
              PopperComponent={({ children, ...other }) => (
                <Popper {...other} style={{ width: 600 }} placement="bottom-start">{children}</Popper>
              )}
            />
            <Button onClick={handleOpenDialog}>Новий клієнт</Button>
            <NovPost />
          </Box>

          <Box sx={{ display: "flex" }}>
            <TextField
              label="Населений пункт"
              margin="dense"
              variant="outlined"
              InputProps={{ readOnly: true }}
              value={item?.full_adrs_ukr || ""}
              sx={{ flex: "auto" }}
            />
            <TextField
              label="Адреса"
              margin="dense"
              variant="outlined"
              InputProps={{ readOnly: true }}
              value={
                props.whois === 2 && params.dptnp
                  ? "Відділення НП " + params.dptnp
                  : item?.c_adr || ""
              }
            />
            <TextField
              label="Контактна особа"
              margin="dense"
              variant="outlined"
              InputProps={{ readOnly: true }}
              value={item?.c_name || ""}
            />
            <TextField
              label="Телефон"
              margin="dense"
              variant="outlined"
              InputProps={{ readOnly: true }}
              value={item?.c_tel || ""}
            />
          </Box>
        </Box>
        {EditDialog()}
        <hr />
      </CityContext.Provider>
    </>
  );
}
