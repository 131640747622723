import { React, useState, useEffect, useContext } from "react";
import {
  Select,
  InputLabel,
  MenuItem,
  Box,
  FormControl,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { InputNumber } from "primereact/inputnumber";
import * as consts from "../../../common/data.js";
import { ParamsContext } from "./Context.js";
import { SERVER_API } from "../../../common/env.js";
import { CheckBox } from "@mui/icons-material";

export default function Params(props) {
  const [context, setContext] = useContext(ParamsContext);
  const [payer, setPayer] = useState(null);

  const [returnDelivery, setReturnDelivery] = useState(false);
  const [toUser, setToUser] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(`${SERVER_API}/clients/payer`, {
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          }),
        });
        if (!response.ok)
          throw new Error(`HTTP error! status: ${response.status}`);
        const json = await response.json();
        const data = json.data;
        setPayer(data[0]);
      } catch (err) {
        console.log(err);
        // toast.current.show({ severity: 'error', summary: 'Помилка!', detail: `Помилка виконання` })
      } finally {
      }
    })();
  }, []);

  useEffect(() => {
    let x = 0;
    switch (context.cargo?.value) {
      case "LE":
        x = consts.WEGHT_LE[0].value;
        break;
      case "PL":
        x = consts.WEGHT_PL[0].value;
        break;
      case "GF":
        x = 1;
        break;
      default:
        break;
    }

    console.log('Params', toUser)

    const data = Object.assign({}, context);
    data.weight = x;
    setContext(data);
  }, [context.cargo]);

  const Weight = () => {
    if (["LE", "PL"].includes(context.cargo.value)) {
      const $items = () => {
        const $item = (arr) => {
          return arr.map((e) => (
            <MenuItem key={e.value} value={e.value}>
              {e.name}
            </MenuItem>
          ));
        };

        switch (context.cargo.value) {
          case "LE":
            return $item(consts.WEGHT_LE);
          case "PL":
            return $item(consts.WEGHT_PL);
          default:
            break;
        }
      };
      return (
        <>
          <InputLabel>Вага, кг</InputLabel>
          <Select
            label="Вага, кг"
            // defaultValue={weight}
            value={context.weight}
            onChange={(e) => {
              const data = Object.assign({}, context);
              data.weight = e.target.value;
              setContext(data);
            }}
          >
            {$items()}
          </Select>
        </>
      );
    }

    return (
      <TextField
        label="Вага, кг"
        variant="outlined"
        value={context.weight}
        onChange={(e) => {
          const data = Object.assign({}, context);
          data.weight = e.target.value;
          setContext(data);
        }}
        type="number"
        InputProps={{
          inputProps: {
            min: 1,
            max: 999999,
          },
        }}
      />
    );
  };

  return (
    <>
      <Box>
        <h4>Параметри</h4>
        <Box sx={{ width: 1, display: "flex", flexwrap: "wrap" }}>
          <FormControl sx={{ width: 1 / 6, minwidth: 1 / 6 }} margin="dense">
            <InputLabel>Сервіс</InputLabel>
            <Select
              label="Сервіс"
              defaultValue={context.service}
              value={context.service}
              onChange={(e) =>
                setContext({ ...context, service: e.target.value })
              }
            >
              {consts.SERVICE.map((e) => (
                <MenuItem key={e.value} value={e}>
                  {e.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ width: 1 / 6, minwidth: 1 / 6 }} margin="dense">
            <InputLabel>Формат</InputLabel>
            <Select
              label="Формат"
              defaultValue={context.cargo}
              value={context.cargo}
              onChange={(e) =>
                setContext({ ...context, cargo: e.target.value })
              }
            >
              {consts.CARGO.map((e) => (
                <MenuItem key={e.value} value={e}>
                  {e.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ width: 1 / 6, minwidth: 1 / 6 }} margin="dense">
            <TextField
              label="Кількість місць"
              variant="outlined"
              value={context.place}
              onChange={(e) =>
                setContext({ ...context, place: e.target.value })
              }
              type="number"
              InputProps={{
                inputProps: {
                  min: 1,
                  max: 10,
                },
              }}
            />
          </FormControl>

          <FormControl sx={{ width: 1 / 6, minwidth: 1 / 6 }} margin="dense">
            {Weight()}
          </FormControl>

          {/* <FormControl sx={{ minwidth: 1 / 6 }} margin="dense">
            <InputLabel>Доставка</InputLabel>
            <Select label="Доставка" defaultValue={''}>
              {consts.DELIVERY.map(e => (<MenuItem key={e.value} value={e.value}>{e.name}</MenuItem>))}
            </Select>
          </FormControl> */}

          {/* <FormControl sx={{ minwidth: 1 / 6 }} margin="dense">
            <InputLabel>Тип корреспонденції</InputLabel>
            <Select label="Тип корреспонденції" defaultValue={''}>
              {consts.ATTACHMENT.map(e => (<MenuItem key={e.value} value={e.value}>{e.name}</MenuItem>))}
            </Select>
          </FormControl> */}

          <FormControl sx={{ width: 1 / 6, minwidth: 1 / 6 }} margin="dense">
            <TextField
              label="Оціночна вартість, грн."
              variant="outlined"
              value={context.cost}
              onChange={(e) => setContext({ ...context, cost: e.target.value })}
              type="number"
              InputProps={{
                inputProps: {
                  min: 0.0,
                  max: 999999.99,
                },
              }}
            />
          </FormControl>

          <FormControl sx={{ width: 1 / 6, minwidth: 1 / 6 }} margin="dense">
            <TextField
              label="Платник"
              variant="outlined"
              sx={{ width: 1 }}
              value={payer?.cacc || ""}
              InputProps={{
                readOnly: true,
              }}
            />
          </FormControl>
        </Box>

        <Box
          sx={{
            width: 1,
            display: "flex",
            flexwrap: "wrap",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: 0.3,
              display: "flex",
              flexwrap: "wrap",
              flexDirection: "column",
              fontSize: 12,
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  size='small'
                  checked={context.redeliv}
                  onChange={(e) => setContext({ ...context, redeliv: e.target.checked})}
                  color="primary"
                />
              }
              
              label="Зворотня доставка"
            />

            <FormControlLabel
              sx={{fontWeight:'light', fontSize: 12,}}
              label="Особисто у руки"
              control={
                <Checkbox
                  size="small"
                  checked={context.pers}
                  onChange={(e) => setContext({ ...context, pers: e.target.checked})}
                  color="primary"
                />
              }
              
            />
          </Box>

          <FormControl fullWidth margin="dense">
            <TextField
              label="Примітка"
              variant="outlined"
              value={context.note}
              onChange={(e) => setContext({ ...context, note: e.target.value })}
            />
          </FormControl>
        </Box>
      </Box>
      <hr />
    </>
  );
}
