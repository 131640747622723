import { Header } from "../Header/Header";
import { Outlet } from "react-router-dom";
import { Footer } from "../Footer/Footer";
import { Container } from "../GlobalStyle/Container.styled";
import { styled } from "@mui/system";

const RootContainer = styled("div")({
  // display: "flex",
  // flexDirection: "column",
  // minHeight: "100vh",
});

const ContentContainer = styled(Container)({
  // flexGrow: 1,
  minHeight: "80vh",
});

export const SharedLayout = () => {
  return (
    <RootContainer>
      <Header />
      <ContentContainer component="main">
        <Outlet />
      </ContentContainer>
      <Footer />
    </RootContainer>
  );
};
