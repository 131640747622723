import React from "react";
import { HeaderContainer, Navigation, StyledHeader } from "./Header.styled";
import { Menu } from "./Menu/Menu";
import { Container } from "../GlobalStyle/Container.styled";

export const Header = () => {
  return (
    <StyledHeader>
      <Container>
        <HeaderContainer>
          <Navigation>
            <Menu />
          </Navigation>
        </HeaderContainer>
      </Container>
    </StyledHeader>
  );
};
