import React from 'react'


export const initPointer = {
        country_id: 20,
        city_id: null,
        street: "",
        bldg: "",
        flat: "",
        client: "",
        contact: "",
        phone: "",
        email: ""
}



//Формат корреспонденции
export const CARGO = [
    { value: 'LE', name: "Конверт" },
    { value: 'PL', name: "Посилка" },
    { value: 'GF', name: "Вантаж" }
]
//Сервис доставки
export const SERVICE = [
    { value: 'ST', name: "Стандарт" },
    { value: 'EX', name: "Экспрес" },
    { value: 'SEX', name: "Суперэкспрес" }
]
//Доставка
export const DELIVERY = [
    { value: 1, name: "Адреса-Адреса" },
]
//Тип корреспонденции
export const ATTACHMENT = [
    { value: 1, name: "Документы" },
    { value: 2, name: "Не документы" },
]
//Способ оплаты
export const PAYMENT = [
    { value: 1, name: "Готівка (Visa/Mastercard)" },
    { value: 2, name: "Безготівковий (рахунок)" },
]
//Плательщик
export const PAYER = [
    { value: 1, name: "Відправник" },
    { value: 2, name: "Отримувач" }
]
//Вес конверта
export const WEGHT_LE = [
    { value: 0.2, name: "0.2" },
    { value: 0.5, name: "0.5" },
    { value: 1.0, name: "1.0" },
]
//Вес посылки
export const WEGHT_PL = [
    { value: 1, name: "1.0" },
    { value: 2, name: "2.0" },
    { value: 5, name: "5.0" },
    { value: 10, name: "10.0" },
]


export const initParams = {
    cargo: CARGO[0].value,              // Формат корреспонденции
    service: SERVICE[0].value,          // Сервис доставки
    delivery: DELIVERY[0].value,        // Доставка
    attachment: ATTACHMENT[0].value,    // Тип корреспонденции
    payer: PAYER[0].value,              // Способ оплаты
    payment: PAYMENT[0].value,          // Плательщик
    cost: 200,                          // Оценочная стоимость
    place: 1,                           // к-во мест
    weight: WEGHT_LE[0].value,          // вес
    note: '',                           // Примечание
}


export const SenderContext = React.createContext(null)
export const ReceiverContext = React.createContext(null)
export const ParamsContext = React.createContext(null)
