import React from "react";
import { useState, useEffect, useRef, useContext } from "react";
import { SERVER_API } from "../common/env";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/material";
import { Toast } from "primereact/toast";

const LIST_SIZE = 15;

export default function City(props) {

  //console.log('COMPONENT CITY Props...',props)
  
  const toast = useRef();

  const [context, setContext] = useContext(props.context);

  const [isLoading, setIsLoading] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [item, setItem] = useState(null);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);

  const refInput = useRef();
  const refWrapList = useRef();
  const refList = useRef();

  //console.log('refInput', refList)
  //console.log('refWrapList', refList)
  //console.log('refList', refList)
  //console.log('page', page)

  //console.log('COMPONENT CITY Item...',item)
  //console.log('COMPONENT CITY Item...',searchString)
  useEffect(() => {
    document.addEventListener("click", onDocumentClick, false);
    return () => {
      document.removeEventListener("click", onDocumentClick);
    };
  }, []);

  useEffect(() => {
    if (context?.city_id) {
      setIsLoading(true);
      //setPage(1);
      fetch(
        `${SERVER_API}/countries/${context?.country_id || 20}/cities/${
          context?.city_id
        }`,
        {
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          }),
        }
      )
        .then((data) => {
          return data.json();
        })
        .then((json) => {
          if (json.data) {
            const data = json.data;
            setItems((items) => items.concat(data));
            setItem(data[0]);
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => setIsLoading(false));
    }
  }, []);

  useEffect(() => {
    if (item) {
      setContext(item);
      setSearchString(getTextList(item));
    }
  }, [item]);

  useEffect(() => {
    (async () => await loadData())();
  }, [page, searchString]);

  const loadData = async () => {
    if (page === 0) return;
    if (searchString === "") return;
    setIsLoading(true);
    try {
      const response = await fetch(
        `${SERVER_API}/countries/${context.country_id}/cities/?filter=${searchString}&page=${page}`,
        {
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          }),
        }
      );
      if (!response.ok) throw Error("Error: can`t fetch data from server!");
      const json = await response.json();
      const data = json.data;
      setItems((items) => items.concat(data));
      data.forEach((e) => {
        const option = document.createElement("option");
        option.value = e.city_id;
        option.text = getTextList(e);
        refList.current.appendChild(option);
      });
    } catch (err) {
      setItems([]);
      console.log(err.message);
      toast.current.show({
        severity: "error",
        summary: "Помилка!",
        detail: `Помилка виконання`,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeInput = (e) => {
    if (isLoading) return;
    refWrapList.current.style.display = "block";
    const value = e.target.value;
    refList.current.innerHTML = "";
    setItem(null);
    setItems([]);
    setSearchString(value);
    setPage(1);
  };

  const onClickList = (e) => {
    const i = refList.current.selectedIndex;
    setItem(items[i]);
    setSearchString("");

    setTimeout(() => {
      refWrapList.current.style.display = "none";
    }, 100);
  };

  const onKeyPressList = (e) => {
    if (e.key === "Enter") {
      onClickList(e);
    }
  };

  const onKeyPressInput = (e) => {
    
    let i = refList.current.selectedIndex;
    //console.log('onKeyPressInput',e.key,'page',page,'i',i,'items.length ',items.length);
    switch (e.key) {
      case "ArrowUp":
        i = Math.max(0, i - 1);
        break;
      case "ArrowDown":
        i = Math.min(items.length - 1, i + 1);
        if (i===14) {setPage((page) => page + 1)}
        break;
      case "Enter":
        onClickList();
        break;
      case "Escape":
        refWrapList.current.style.display = "none";
        break;
      default:
        break;
    }
    refList.current.selectedIndex = i;
  };

  const onScrollList = async (event) => {
    //console.log('onScrollList isLoading', isLoading)
    if (isLoading) return;
    let e = event.target;
    if (e.scrollHeight - e.scrollTop === e.clientHeight) {
      setPage((page) => page + 1);
    }
  };

  const getTextList = (e) => {
    //console.log('getTextList', e)
    return e
      ? (!e.city_ukr_name ? "" : e.city_ukr_name.trim()) +
          (!e.city_type_ukr_name ? "" : " " + e.city_type_ukr_name.trim()) +
          (!e.state_ukr_name ? "" : ", " + e.state_ukr_name.trim()) +
          (!e.state_type_ukr_name ? "" : ", " + e.state_type_ukr_name.trim()) +
          (!e.distr_ukr_name ? "" : ", " + e.distr_ukr_name.trim())
      : "";
  };

  const onDocumentClick = (e) => {
    refWrapList.current.style.display =
      refInput.current.contains(e.target) ||
      refWrapList.current.contains(e.target) ||
      refList.current.contains(e.target)
        ? "block"
        : "none";
  };

  return (
    <>
      <Toast ref={toast} />
      <Box sx={{ width: 1 }}>
        <div style={{ position: "relative" }}>
          <TextField
            id="outlined-basic"
            required
            ref={refInput}
            sx={{ width: 1 }}
            label={props.label} //"Населений пункт"
            variant="outlined"
            autoComplete="off"
            value={searchString}
            onKeyUp={onKeyPressInput}
            onInput={onChangeInput}
            error={!!props.error}
            helperText={props.helperText}
          />
          <div
            id="wrap-list"
            style={{
              position: "absolute",
              width: "100%",
              zIndex: 100,
              display: "none",
            }}
            ref={refWrapList}
          >
            <select
              id="list"
              size={LIST_SIZE}
              style={{ width: "100%" }}
              ref={refList}
              onClick={onClickList}
              onKeyDown={onKeyPressList}
              onWheel={onScrollList}
            />
          </div>
        </div>
      </Box>
    </>
  );
}
