import React from 'react'
import * as data from '../../../common/data'

export const InitParams = {
  cargo: data.CARGO[0],               // Формат корреспонденции
  service: data.SERVICE[0],           // Сервис доставки
  // delivery: data.DELIVERY[0],      // Доставка
  // attachment: data.ATTACHMENT[0],  // Тип корреспонденции
  // payer: data.PAYER[0],            // Способ оплаты
  // payment: data.PAYMENT[0],        // Плательщик
  cost: 0,                            // Оценочная стоимость
  place: 1,                           // к-во мест
  weight: data.WEGHT_LE[0].value,     // вес
  note: '',                           // Примечание
  dptnp: '',                          // отделение новой почты
  redeliv: false,              // зворотня доставка
  pers: false,                      // особисто у руки
}

export const SenderContext = React.createContext()
export const ReceiverContext = React.createContext()
export const ParamsContext = React.createContext()
