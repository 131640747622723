import React from "react";
import { Container } from "../GlobalStyle/Container.styled";
import { StyledFooter, StyledLink, StyledText } from "./Footer.styled";

export const Footer = () => {
  return (
    <StyledFooter>
      <Container>
        <StyledText>
          Copyright ©{" "}
          <StyledLink href="https://exmoto.com/">ТОВ "EXMOTO"</StyledLink> 2024.
        </StyledText>
      </Container>
    </StyledFooter>
  );
};
