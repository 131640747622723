import React from "react";
import { ROUTES } from "./Rotuer";

import { Navigate } from "react-router-dom";

export const PrivateRoute = ({
  component: Component,
  redirectTo = ROUTES.MAIN,
}) => {
  const isLoggedIn = localStorage.getItem("jwt");
  const isRefreshing = localStorage.getItem("jwt"); // коли на бек додадуть рефреш токену то змінити!

  const shouldRedirect = !isLoggedIn && !isRefreshing;

  return shouldRedirect ? <Navigate to={redirectTo} /> : Component;
};
